<template>
  <gf-card>
    <el-dialog v-loading="addCustomerModalLoading" :close-on-click-modal="false" :visible.sync="addCustomerModalVisible" append-to-body title="Add Customer">
      <gf-form>
        <el-form ref="customer" :model="customer" :rules="customerRules" label-width="140px" size="small">
          <el-form-item>
            <span class="font-weight-normal text-dark">Customer Detail</span>
          </el-form-item>
          <el-form-item label="Customer Name" prop="name">
            <el-input v-model="customer.name"></el-input>
          </el-form-item>
          <el-form-item label="Company Type">
            <el-select v-model="customer.type" style="width: 100%">
              <el-option :value="1" label="Business Customer"></el-option>
              <el-option :value="2" label="Consumer"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Code" prop="code">
            <el-input v-model="customer.code"></el-input>
          </el-form-item>
          <el-form-item label="Phone Number" prop="phone_number">
            <el-input v-model="customer.phone_number"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="font-weight-normal text-dark">Address</span>
          </el-form-item>
          <el-form-item :rules="{ required: true, message: 'Address Line 1 is require' }" label="Address Line 1" prop="address.0.address_line_1">
            <el-input v-model="customer.address[0].address_line_1"></el-input>
          </el-form-item>
          <el-form-item label="Address Line 2" prop="address.0.address_line_2">
            <el-input v-model="customer.address[0].address_line_2"></el-input>
          </el-form-item>
          <el-form-item label="City" prop="address.0.city">
            <el-input v-model="customer.address[0].city"></el-input>
          </el-form-item>
          <el-form-item label="Zip Code" prop="address.0.zip_code">
            <el-input v-model="customer.address[0].zip_code"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <gf-button type="secondary" @click="addCustomerModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <g fill="#000000" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)">
                      <rect height="2" rx="1" width="16" x="0" y="7"/>
                      <rect height="2" opacity="0.3" rx="1" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " width="16" x="0" y="7"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="primary" @click="addCustomer">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M17,4 L6,4 C4.79111111,4 4,4.7 4,6 L4,18 C4,19.3 4.79111111,20 6,20 L18,20 C19.2,20 20,19.3 20,18 L20,7.20710678 C20,7.07449854 19.9473216,6.94732158 19.8535534,6.85355339 L17,4 Z M17,11 L7,11 L7,4 L17,4 L17,11 Z" fill="#000000" fill-rule="nonzero"/>
                    <rect fill="#000000" height="5" opacity="0.3" rx="0.5" width="3" x="12" y="4"/>
                  </g>
                </svg>
              </span>
            </template>
            Save
          </gf-button>
        </template>
      </gf-form>
    </el-dialog>
    <el-form ref="so" :model="so" :rules="soRules" label-width="110px">
      <gf-content v-loading="loading" overflow>
        <template #title>
          <div class="gf-title">
            <div v-if="selectedCustomer.name" class="name"><p>{{ selectedCustomer.name }} <i class="el-icon-close" @click="removeSelectedCustomer"></i></p> </div>
            <div v-else class="company-select">
              <el-form-item class="mb-0" label-width="0" prop="customer_id">
                <el-select v-model="so.customer_id" :remote-method="searchCustomer" filterable placeholder="Select Customer" remote size="medium" @change="changeSelectedCustomer">
                  <el-option v-for="customer in customers" :key="customer.id" :label="customer.name" :value="customer.id"></el-option>
                </el-select>
              </el-form-item>
              <span> or </span>
              <el-button type="text" @click="addCustomerModalVisible=true">create a new customer</el-button>
            </div>
          </div>
        </template>
        <template #toolbar>
          <div class="gf-toolbar">
            <div class="number">
              <p>#{{ so.so_number }}</p>
            </div>
          </div>
        </template>
        <template #header>
          <el-row :gutter="20">
            <el-col :lg="8" :md="12" :sm="24">
              <el-form-item label="Bill To" label-width="75px" prop="bill_to_id">
                <el-select v-model="so.bill_to_id" placeholder="Select" size="small" style="width: 100%;">
                  <el-option v-for="ad in customer_addresses" :key="ad.id" :label="ad.label" :value="ad.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Ship To" label-width="75px" prop="ship_to_id">
                <el-select v-model="so.ship_to_id" placeholder="Select" size="small" style="width: 100%;">
                  <el-option v-for="ad in customer_addresses" :key="ad.id" :label="ad.label" :value="ad.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" :sm="24">
              <el-form-item label="Issued Date" label-width="120px">
                <el-date-picker v-model="so.issue_date" format="dd MMM yyyy" size="small" style="width: 100%;" type="date" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Shipment Date" label-width="120px">
                <el-date-picker v-model="so.shipment_date" format="dd MMM yyyy" size="small" style="width: 100%;" type="date" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Reference" label-width="120px">
                <el-input v-model="so.reference" size="small" style="width: 100%;"></el-input>
              </el-form-item>
              <el-form-item label="Email" label-width="120px" prop="email">
                <el-input v-model="so.email" size="small" style="width: 100%;"></el-input>
              </el-form-item>
              <el-form-item label="Phone" label-width="120px" prop="phone">
                <el-input v-model="so.phone" size="small" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12" :sm="24">
              <el-form-item label="Price List">
                <el-select v-model="so.price_list_id" placeholder="Select" size="small" style="width: 100%;">
                  <el-option v-for="pl in priceLists" :key="pl.id" :label="pl.name" :value="pl.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Totals are">
                <el-select v-model="so.is_inclusive" placeholder="Select" size="small" style="width: 100%;">
                  <el-option :value="0" label="Tax Exclusive"></el-option>
                  <el-option :value="1" label="Tax Inclusive"></el-option>
                  <el-option :value="2" label="No Invoice"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Assigned To" prop="assigned_to_id">
                <el-select v-model="so.assigned_to_id" :remote-method="searchUser" filterable placeholder="Select" remote size="small" style="width: 100%;">
                  <el-option v-for="user in users" :key="user.id" :label="user.fname + ' ' + user.lname" :value="user.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <div class="table">
          <gf-table :data="so.items" :scrollable="false">
            <el-table-column label="Item Name" min-width="160">
              <template slot-scope="slot">
                <el-form-item v-if="selectedItems[slot.$index] === undefined" :prop="'items.' + slot.$index + '.variant_id'" :rules="{ required: true, message: ' ', trigger: 'change' }" label-width="0">
                  <el-select v-model="slot.row.variant_id" :remote-method="searchVariant" filterable placeholder="Start typing SKU or Name" popper-class="gf-select-product" remote size="mini" style="width: 100%" @change="changeSelectedItem($event, slot.$index)">
                    <el-option v-for="variant in variants" :key="variant.id" :label="variant.name" :value="variant.id">
                      <div v-if="variant.product.name === variant.name && variant.is_pack === 0" class="gf-select-product__name">{{ variant.name }}</div>
                      <div v-else-if="variant.is_pack === 0" class="gf-select-product__name">{{ variant.product.name }}  - {{ variant.name }}</div>
                      <div v-else class="gf-select-product__name">
                        <span v-if="variant.product.name === variant.variant.name">{{ variant.variant.name }} - {{ variant.name }}</span>
                        <span v-else>{{ variant.product.name }} - {{ variant.variant.name }} - {{ variant.name }}</span>
                        <span v-if="variant.is_pack === 1"> (Pack of {{ variant.pack_size }})</span>
                      </div>
                      <div class="gf-select-product__sub">
                        <span class="gf-select-product__sub__sku">SKU: {{ variant.sku }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <div v-else class="gf-item">
                  <div v-if="selectedItems[slot.$index].product.name === selectedItems[slot.$index].name && selectedItems[slot.$index].is_pack === 0" class="gf-item__name">{{ selectedItems[slot.$index].name }}</div>
                  <div v-else-if="selectedItems[slot.$index].is_pack === 0" class="gf-item__name">{{ selectedItems[slot.$index].product.name }} - {{ selectedItems[slot.$index].name }}</div>
                  <div v-else class="gf-item__name">
                    <span v-if="selectedItems[slot.$index].product.name === selectedItems[slot.$index].variant.name">{{ selectedItems[slot.$index].variant.name }} - {{ selectedItems[slot.$index].name }}</span>
                    <span v-else>{{ selectedItems[slot.$index].product.name }} - {{ selectedItems[slot.$index].variant.name }} - {{ selectedItems[slot.$index].name }}</span>
                    <span>(Pack of {{ selectedItems[slot.$index].pack_size }})</span>
                  </div>
                  <div class="gf-item__sub">
                    <span class="gf-item__sub__sku">SKU: {{ selectedItems[slot.$index].sku }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Quantity" min-width="120">
              <template slot-scope="slot">
                <el-form-item :prop="'items.' + slot.$index + '.quantity'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                  <el-input v-model="slot.row.quantity" size="mini" style="width: 100%"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Available" min-width="120">
              <template  slot-scope="slot">
                <el-input :value="$NumberFormat(slot.row.stock)" readonly size="mini" style="width: 100%"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Price" min-width="100">
              <template slot-scope="slot">
                <el-form-item :prop="'items.' + slot.$index + '.cost'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                  <el-input v-model="slot.row.cost" size="mini" style="width: 100%"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Discount" min-width="120">
              <template slot-scope="slot">
                <el-form-item :prop="'items.' + slot.$index + '.discount'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                  <el-input v-model="slot.row.discount" size="mini" style="width: 100%"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column v-if="so.is_inclusive !== 2" label="Tax" min-width="100">
              <template slot-scope="slot">
                <el-form-item :prop="'items.' + slot.$index + '.tax'" :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                  <el-input v-model="slot.row.tax" size="mini" style="width: 100%"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Total" min-width="120">
              <template  slot-scope="slot">
                <el-input :value="$NumberFormat(slot.row.cost * slot.row.quantity - (slot.row.cost * slot.row.quantity * (slot.row.discount / 100)))" readonly size="mini" style="width: 100%"></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" label="" width="80">
              <template slot-scope="slot">
                <el-button v-model="slot.row.delete" icon="el-icon-delete" size="mini" type="danger" @click="deleteItem(slot.$index)"></el-button>
              </template>
            </el-table-column>
          </gf-table>
        </div>
        <div class="add-item">
          <gf-button light size="sm" style="width: 100%" type="primary" @click="addItem">+ Add another item</gf-button>
        </div>
        <div class="footer mt-4">
          <div class="note">
            <el-input v-model="so.message" :rows="2" placeholder="Message to Supplier" type="textarea"></el-input>
          </div>
          <div class="gf-summary">
            <ul class="summary">
              <li class="summary__item">
                <span class="text">Total Units</span>
                <span class="value">{{ $NumberFormat(totalUnits) }}</span>
              </li>
              <li v-if="so.is_inclusive !== 2" class="summary__item">
                <span class="text">Subtotal</span>
                <span class="value">{{ $NumberFormat(subtotal) }}</span>
              </li>
              <div v-if="so.is_inclusive !== 2">
                <li v-for="(vat, index) in vats" :key="index" class="summary__item">
                  <span class="text">{{ (so.is_inclusive) ? 'Including': 'Plus' }} VAT ({{ vat.rate }}%)</span>
                  <span class="value">{{ $NumberFormat(vat.value) }}</span>
                </li>
              </div>
              <li class="summary__item">
                <span class="text">Total</span>
                <span class="value">{{ $NumberFormat(total) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <template #footer>
          <div class="text-right">
            <gf-button class="mr-3" size="sm" type="primary" @click="create('submit')">Submit</gf-button>
            <gf-button class="mr-3" size="sm" type="warning" @click="create('draft')">Save As Quote</gf-button>
            <gf-button size="sm" type="secondary" @click="cancel">Cancel</gf-button>
          </div>
        </template>
      </gf-content>
    </el-form>
  </gf-card>
</template>

<style lang="scss" scoped>
.content {
  .el-form-item  {
    width: auto;
    margin: 0;
  }
}

.el-form {
  height: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}
</style>

<script>
// Services
import CompanyService from '@/services/v1/Company'
import CompanyAddressService from '@/services/v1/CompanyAddress'
import PriceListService from '@/services/v1/PriceList'
import VariantService from '@/services/v1/Variant'
import ProductVariantService from '@/services/v1/ProductVariant'
import SalesOrderService from '@/services/v1/SalesOrder'
import UserService from '@/services/v1/User'

export default {
  prop: {
    loaded: Boolean,
    updateChild: Boolean
  },
  data () {
    return {
      salesOrderId: this.$route.params.id,

      // ui
      loading: false,

      so: {
        so_number: 'SO000000',
        customer_id: null,
        bill_to_id0: null,
        ship_to_id: null,
        ship_from_id: 1,
        issue_date: this.$DateISOFormat(new Date().toISOString()),
        shipment_date: this.$DateISOFormat(new Date().toISOString()),
        reference: null,
        email: null,
        phone: null,
        currency_id: 1,
        price_list_id: 2,
        is_inclusive: 0,
        assigned_to_id: null,
        message: null,
        items: []
      },
      selectedCustomer: {
        name: null
      },
      selectedItems: [],
      discount: 0,
      price_list_id: null,

      // rules
      soRules: {
        customer_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        so_number: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        bill_to_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        ship_to_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        ship_from_id: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        email: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        assigned_to_id: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      },
      customerRules: {
        name: [
          { required: true, message: 'Name is require', trigger: 'blur' }
        ]
      },

      // variables
      customers: [],
      customer_addresses: [],
      priceLists: [],
      users: [],
      variants: [],
      customer: {
        name: null,
        type: null,
        code: null,
        phone_number: null,
        address: [
          {
            label: 'New Address',
            address_line_1: null,
            address_line_2: null,
            city: null,
            zip_code: null
          }
        ]
      },

      addCustomerModalVisible: false,
      addCustomerModalLoading: false,

      // helpers
      mode: 'create',

      // filters
      customerFilter: {
        'cus.type': {
          '!=': 3
        }
      },
      userFilter: {},
      variantFilter: {}
    }
  },
  computed: {
    totalUnits () {
      let total = 0

      for (let i = 0; i < this.so.items.length; i++) {
        const e = this.so.items[i]
        total += parseFloat(e.quantity)
      }

      return total
    },
    subtotal () {
      let total = 0

      if (this.so.is_inclusive === 0) {
        for (let i = 0; i < this.so.items.length; i++) {
          const e = this.so.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const discount = sub * parseFloat(e.discount / 100)
          total += sub - discount
        }
      } else {
        for (let i = 0; i < this.so.items.length; i++) {
          const e = this.so.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const discount = sub * parseFloat(e.discount / 100)
          const discounted = sub - discount
          const tax = parseFloat(e.tax) / 100
          total += discounted - (discounted * tax)
        }
      }

      return total
    },
    vats () {
      const vt = []

      if (this.so.items.length > 0) {
        for (let i = 0; i < this.so.items.length; i++) {
          const e = this.so.items[i]
          const found = vt.find(vat => vat.rate === e.tax)

          if (found) {
            found.value += (e.tax / 100) * (parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100)))
          } else {
            vt.push({
              rate: e.tax,
              value: (e.tax / 100) * (parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100)))
            })
          }
        }
      }

      return vt
    },
    total () {
      let total = 0

      if (this.so.items.length > 0) {
        for (let i = 0; i < this.so.items.length; i++) {
          const e = this.so.items[i]
          total += parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100))
        }
      }

      if (!this.so.is_inclusive) {
        const vats = this.vats

        for (let i = 0; i < vats.length; i++) {
          const vat = vats[i]
          total += parseFloat(vat.value)
        }
      }

      return total
    }
  },
  watch: {
    updateChild: function () {
      if (this.updateChild) {
        this.$emit('update:updateChild', false)
        this.$router.push({ name: 'orders-view', params: { id: this.$route.params.id } })
      }
    }
  },
  methods: {
    addItem () {
      this.so.items.push({ variant_id: null, stock: 0, quantity: 1, cost: 0, discount: this.discount, tax: 12.00 })
    },
    deleteItem (index) {
      this.so.items.splice(index, 1)
      this.selectedItems.splice(index, 1)
    },
    cancel () {
      this.$router.push({ name: 'order-list' })
    },
    create (status) {
      this.$refs.so.validate()
        .then(async () => {
          try {
            this.loading = true

            if (this.so.items.length === 0) {
              this.$notify.error({
                title: 'Error',
                message: 'Sales Order must contain at least 1 item'
              })
              return
            }

            const so = this.$_.cloneDeep(this.so)
            this.$Sanitize(so)

            for (let i = 0; i < so.items.length; i++) {
              const item = so.items[i]
              delete item.stock
              delete item.stocks
              delete item.variant
              delete item.product
              delete item.created_at
              delete item.updated_at
            }

            so.units = this.totalUnits
            so.subtotal = this.subtotal
            so.vats = JSON.stringify(this.vats)
            so.total = this.total

            let id = this.salesOrderId
            if (this.mode === 'create') {
              const soService = new SalesOrderService()

              let result = null
              if (status === 'submit') {
                result = await soService.create(so)
                this.$message.success(`${this.so.so_number} successfully created`)
              } else {
                result = await soService.createDraft(so)
                this.$message.success(`${this.so.so_number} successfully created`)
              }

              id = result.data.id
            } else {
              const soService = new SalesOrderService(id)

              if (status === 'submit') {
                await soService.submit(so)
                this.$message.success(`${this.so.so_number} successfully submitted`)
              } else {
                await soService.updateDraft(so)
                this.$message.success(`${this.so.so_number} successfully updated`)
              }
            }

            this.$emit('update:loaded', false)
            this.$router.push({ name: 'order-view', params: { id } })
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },

    // Selection
    async changeSelectedCustomer (id) {
      try {
        const cust = this.customers.find(cus => cus.id === id)
        const name = cust.name
        this.selectedCustomer.name = name
        this.getAddresses(this.so.customer_id)

        const cmService = new CompanyService(cust.id)
        const result = await cmService.get()
        const setting = result.data.setting[0] || { customer_group: null }
        const cg = setting.customer_group

        if (cg) {
          this.discount = cg.discount_rate || 0
          this.so.price_list_id = cg.price_list_id || 2
        } else {
          this.discount = setting.discount || 0
          this.so.price_list_id = setting.price_list_id || 2
        }
      } catch (error) {
        this.$Error(error)
      }
    },
    removeSelectedCustomer () {
      this.selectedCustomer.name = null
      this.so.bill_to_id = null
      this.so.ship_to_id = null
      this.searchCustomer('')
    },
    changeSelectedItem (id, index) {
      const exist = this.so.items.filter(item => item.variant_id === id).length

      if (exist > 1) {
        this.$notify.error({
          title: 'Duplicate Entry',
          message: 'Item already exist!'
        })
        this.so.items[index].variant_id = null
        return
      }

      const item = this.variants.find(vr => vr.id === id)
      this.selectedItems[index] = item

      const price = item.prices.find(pl => pl.price_list_id === this.so.price_list_id)
      if (!price) this.so.items[index].cost = 0
      else this.so.items[index].cost = parseFloat(price.value)

      const stock = item.stocks[0] || { value: 0, commited: 0 }
      this.so.items[index].stock = parseFloat(stock.value) - parseFloat(stock.commited || 0)

      if (item.is_pack) {
        this.so.items[index].stock = (parseFloat(stock.value) - parseFloat(stock.commited || 0)) / parseFloat(item.pack_size)
      } else {
        this.so.items[index].stock = parseFloat(stock.value) - parseFloat(stock.commited || 0)
      }
    },
    async changeSelectedExistingItem () {
      for (let i = 0; i < this.so.items.length; i++) {
        const item = this.so.items[i]
        const vr = await this.getVariant(item.product.id, item.variant_id)
        this.selectedItems[i] = vr

        const stock = vr.stocks.find(item => item.warehouse_id === this.so.ship_from_id) || { value: 0, commited: 0 }
        if (vr.is_pack) {
          this.so.items[i].stock = (parseFloat(stock.value) - parseFloat(stock.commited || 0)) / parseFloat(vr.pack_size)
        } else {
          this.so.items[i].stock = parseFloat(stock.value) - parseFloat(stock.commited || 0)
        }

        this.so.items[i].id = item.id
        this.so.items[i].variant_id = item.variant_id
        this.so.items[i].cost = parseFloat(item.cost)
        this.so.items[i].quantity = parseFloat(item.quantity)
        this.so.items[i].tax = parseFloat(item.tax)
      }
    },

    // Modal
    addCustomer () {
      this.$refs.customer.validate()
        .then(async () => {
          try {
            this.addCustomerModalLoading = true
            const customer = this.$_.cloneDeep(this.customer)

            this.$Sanitize(customer)
            this.$Sanitize(customer.address[0])

            const cmService = new CompanyService()
            await cmService.create(customer)

            this.$message.success(`${customer.name} successfully created`)
            this.$ClearField('customer')

            // clear
            this.addCustomerModalVisible = false
            await this.getCompanies()
          } catch (error) {
            this.$Error(error)
          } finally {
            this.addCustomerModalLoading = false
          }
        })
        .catch(() => {})
    },

    // Select Search Method
    async searchVariant (query) {
      try {
        if (query !== '') {
          this.variantFilter.q = {
            like: query
          }
        } else delete this.variantFilter.q

        await this.getVariants()
      } catch (error) {
        this.$Error(error)
      }
    },
    async searchCustomer (query) {
      if (query !== '') {
        this.customerFilter.q = {
          like: query
        }
      } else delete this.customerFilter.q

      await this.getCompanies()
    },
    async searchUser (query) {
      if (query !== '') {
        this.userFilter.q = {
          like: query
        }
      } else delete this.userFilter.q

      await this.getUsers()
    },

    // Service fetch
    async getCompanies () {
      try {
        const cmService = new CompanyService()
        const result = await cmService.list(null, { cus: ['id', 'name'] }, this.customerFilter)
        this.customers = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getAddresses (id) {
      try {
        const caService = new CompanyAddressService(id)
        const result = await caService.list()
        this.customer_addresses = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getPriceLists () {
      try {
        const plService = new PriceListService()
        const result = await plService.list(null, null, { 'pl.price_list_type': { '=': 1 } })
        this.priceLists = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getUsers () {
      try {
        const usService = new UserService()
        const result = await usService.list(null, { user: ['id', 'fname', 'lname'] }, this.userFilter)
        this.users = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getVariant (pid, id) {
      try {
        const pvService = new ProductVariantService(pid, id)
        const result = await pvService.get()
        return result.data
      } catch (error) {
        this.$Error(error)
      }
    },
    async getVariants () {
      try {
        const vrService = new VariantService()
        const result = await vrService.list(null, null, this.variantFilter)
        this.variants = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async setSONumber () {
      try {
        const soService = new SalesOrderService()
        const result = await soService.getLastSO()
        let soNumber = result.data.so_number

        if (!soNumber) soNumber = 'SO000000'

        const number = parseInt(soNumber.substring(2)) + 1
        this.so.so_number = 'SO' + this.$Numeral(number).format('000000')
      } catch (error) {
        this.$Error(error)
      }
    },
    async getSalesOrder () {
      try {
        this.loading = true
        const soService = new SalesOrderService(this.salesOrderId)
        const result = await soService.getById(this.$route.params.id)
        this.so = result.data
        this.so.issue_date = this.$DateISOFormat(this.so.issue_date)
        this.so.shipment_date = this.$DateISOFormat(this.so.shipment_date)

        await this.searchCustomer(this.so.customer.name)
        await this.searchUser(this.so.assigned_to.fname + ' ' + this.so.assigned_to.lname)
        this.changeSelectedCustomer(this.so.customer_id)

        delete this.so.customer
        delete this.so.assigned_to
        delete this.so.bill_to
        delete this.so.ship_to
        delete this.so.ship_from
        delete this.so.currency
        delete this.so.created_at
        delete this.so.status
        delete this.so.updated_at
        delete this.so.packed
        delete this.so.fulfilled
        delete this.so.invoiced
        delete this.so.paid

        this.changeSelectedExistingItem()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.loading = true
    this.mode = this.$route.meta.mode

    await this.getCompanies()
    await this.getPriceLists()
    await this.getUsers()

    if (this.mode === 'create') {
      await this.setSONumber()
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Order' },
        { title: 'New' }
      ])

      this.loading = false
    } else {
      await this.getSalesOrder()

      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Order' },
        { title: this.so.so_number }
      ])
    }
  }
}
</script>
